$(function(){

    $filterForm = $('#filter-form');
    $filterForm.on('change', '#organization-unit-select', function() {
        $filterForm.submit();
    })
    $filterForm.on('change', '#year-select', function() {
        $filterForm.submit();
    });

    tinymce.init({
        selector: ".wysiwyg",
        menubar : false,
        toolbar: [
           "undo redo | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent",
        ]
    });
});